var sh;
var doc = document.documentElement;

var headerDiv = document.querySelector('.site-header');
var menuToggler = document.querySelector('.site-header .nav-menu .nav-toggler');
var ddTogglers = document.querySelectorAll('.site-header .nav-menu li.has-submenu > a');
var searchIndicator = document.querySelector('.site-header .search-icon');
var searchForm = document.querySelector('.site-header .search-form');
var scrollIndicators = document.querySelectorAll('[data-scroll]');
var heroCarousel = document.querySelector('.herospace');
var stepCarousel = document.querySelector('.step-carousel');
var heroPage = document.querySelector('.page-herospace');
var homeDots = document.querySelectorAll('.scroll-dots a');
var showBtn = document.querySelectorAll('[data-show]');
var formAlert = document.querySelector('.alert');
var popupTriggers = document.querySelectorAll('[data-popup]');

document.addEventListener('DOMContentLoaded', function () {
    // Sticky header
    if (headerDiv) {
        window.addEventListener('scroll', function () {
            sh = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            if (sh > 75) {
                addClass(headerDiv, 'scrolled');
            } else {
                removeClass(headerDiv, 'scrolled');
            }
        })
    }

    // document click
    document.addEventListener('click', function(event) {
        event = event || window.event;

        if (hasClass(menuToggler, 'open')) {
            hideElem(headerDiv.querySelector('.nav-menu ul'));
            removeClass(menuToggler, 'open');
        }

    });

    // Mobile menu
    if (menuToggler) {
        menuToggler.addEventListener('click', function (event) {
            event = event || window.event;
            event.preventDefault();
            event.stopPropagation();
            toggleElem(headerDiv.querySelector('.nav-menu ul'));
            toggleClass(this, 'open');
        });
    }

    // Menu dropdowns
    if (ddTogglers.length) {
        forEach(ddTogglers, function (index, elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                if (hasClass(elem.parentNode, 'has-submenu')) {
                    e.stopPropagation();
                }
                toggleClass(this, 'open');
                toggleElem(elem.parentNode.querySelector('ul.submenu'));
            });
        });
    }

    // Toggle search form
    if (searchIndicator && searchForm) {
        searchIndicator.addEventListener('click', function (e) {
            e.preventDefault();
            toggleClass(this.parentNode, 'open');
            toggleElem(searchForm);
            if (hasClass(this.parentNode, 'open')) {
                searchForm.querySelector('.search-input').focus();
            }
        });
    }

    // Scroll to page content
    if (scrollIndicators) {
        forEach(scrollIndicators, function (index, elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                var st = document.getElementById(this.dataset.scroll);
                var offset = window.innerWidth <= 700 ? 0 : 75;

                if (heroPage) {
                    smoothScroll.animateScroll(st, this, { speed: 1000, offset: 120 });
                } else {
                    smoothScroll.animateScroll(st, this, { speed: 1000, offset: offset });
                }
            });
        });
    }

    // Scrolling to hashed div
    if (window.location.hash) {
        var scrollTarget = document.getElementById(window.location.hash.substring(1, window.location.hash.length));

        if (scrollTarget) {
            smoothScroll.animateScroll(scrollTarget, null, { speed: 500, offset: 75 });
        }
    }

    // Herospace carousel
    if (heroCarousel) {
        var heroFlickity = new Flickity(heroCarousel, {
            autoPlay: 4000,
            wrapAround: true,
            prevNextButtons: false,
            cellSelector: '.hero-slide',
            pageDots: true
        });
    }

    // Step carousel
    if (stepCarousel) {
        var stepFlickity = new Flickity(stepCarousel, {
            autoPlay: false,
            wrapAround: false,
            prevNextButtons: false,
            cellSelector: '.step-carousel-slide',
            pageDots: false
        });

        var stepModifierBtns = document.querySelectorAll('[data-step-modify]');
        forEach(stepModifierBtns, function (index, elem) {
            elem.addEventListener('click', function () {
                stepFlickity.next();
            });
        });

        var stepItems = document.querySelectorAll('.step-item');
        forEach(stepItems, function (index, elem) {
            elem.addEventListener('click', function () {
                stepFlickity.select(index + 1);
            });
        });
    }

    // Navigation dots
    if (homeDots) {
        forEach(homeDots, function (index, elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();

                var st = document.getElementById(elem.dataset.scroll);
                smoothScroll.animateScroll(st, elem, { speed: 1000, offset: 75 });
            });
        });

        jQuery.extend(verge);

        document.addEventListener('scroll', function () {
            $.each($('.scroll-section'), function () {
                if ($.inViewport($(this), -105)) {
                    $('.scroll-dots a').removeClass('is-selected');
                    $('.scroll-dots a').eq($(this).index()).addClass('is-selected');
                }
            });
        });
    }

    // Show something
    if (showBtn) {
        forEach(showBtn, function (index, elem) {
            elem.addEventListener('click', function (e) {
                var target = document.getElementById(elem.dataset.show);
                if (target) {
                    e.preventDefault();
                    showElem(target);
                    elem.parentNode.removeChild(elem);
                }
            });
        });
    }

    // Form has alerts
    if (formAlert) {
        smoothScroll.animateScroll(formAlert, null, { offset: 120, speed: 1000 });
    }

    // Partner popups
    if (popupTriggers) {
        forEach(popupTriggers, function (index, elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();

                $.ajax({
                    url: '/api/get-partner/' + this.dataset.popup,
                    type: 'GET'
                }).done(function (result) {
                    $('.partner-popup-ph').append(result);

                    $(document).on('click', '.popup .close-btn', function () {
                        $('.popup').remove();
                    });

                    $(document).keydown('keydown', function (e) {
                        if (e.keyCode == 27) {
                            $('.popup').remove();
                        }
                    });
                });
            });
        });
    }
});
